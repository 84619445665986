import { useRouter } from 'next/router'
import React, { useState } from 'react'
import {
  chakra,
  HStack,
  Link,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  Button,
  useColorMode,
  Divider,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react'
import { Link as ScrollLink } from 'react-scroll'

import { AiOutlineMenu } from 'react-icons/ai'
import { GoTriangleDown } from 'react-icons/go'
import { FaMoon, FaSun } from 'react-icons/fa'
import { SIGN_IN, SIGN_UP } from '@/lib/constants/authConstants'
import HelpModal from '@/components/Help/HelpModal'

type Menu = {
  id: string
  name: string
  path: string
  type: string
}

interface IProps {
  menus: Menu[]
  canStick: boolean
}

const NavBar = ({ menus, canStick }: IProps) => {
  const { toggleColorMode: toggleMode, colorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenNav,
    onOpen: onOpenNav,
    onClose: onCloseNav,
  } = useDisclosure()

  const [help, setHelp] = useState<any>(false)
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const bg = useColorModeValue('white', 'black')
  const ref = React.useRef<any>()

  const handleHelpModal = () => {
    setHelp(true)
    onOpen()
    onCloseNav()
  }

  const cl = useColorModeValue('gray.800', 'white')
  const router = useRouter()

  const goToPage = (page: string) => {
    const host =
      process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
        ? process.env.NEXT_PUBLIC_APP_HOST
        : process.env.NEXT_PUBLIC_APP_LOCALHOST

    if (page === SIGN_IN) {
      return router.push(host + '/login')
    }
    return router.push(host + '/signup')
  }

  const MobileNavContent = (
    <Drawer placement={'top'} onClose={onCloseNav} isOpen={isOpenNav}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          bg={bg}
          display="flex"
          flexDir={'column'}
          pb={4}
          gap={3}
          w="full"
          justifyContent="center"
          alignItems={'center'}
          textAlign="center"
        >
          <Flex w="full" justifyContent={'right'} mr="5px">
            <CloseButton
              aria-label="Close menu"
              justifySelf="end"
              onClick={onCloseNav}
            />
          </Flex>
          {menus &&
            menus.length > 0 &&
            menus.map(menu =>
              menu.type === 'regular' ? (
                <Link
                  href="/"
                  h="1.5rem"
                  key={menu.name}
                  color={cl}
                  fontSize="1rem"
                  fontWeight="normal"
                  _hover={{ color: 'primaryDark' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  <ScrollLink
                    to={menu.id}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={onCloseNav}
                  >
                    {menu.name}{' '}
                  </ScrollLink>
                </Link>
              ) : menu.type === 'notRegular' ? (
                <Link
                  href="/"
                  h="1.5rem"
                  key={menu.name}
                  color={cl}
                  fontSize="1rem"
                  fontWeight="normal"
                  _hover={{ color: 'primaryDark' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  <ScrollLink
                    to={menu.id}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={() => router.push(menu.path)}
                  >
                    {menu.name}
                  </ScrollLink>
                </Link>
              ) : (
                <Menu key={menu.name}>
                  <MenuButton
                    color={cl}
                    _hover={{ color: 'primaryDark' }}
                    _focus={{ boxShadow: 'none' }}
                    background="none"
                    _active={{ background: 'none' }}
                    as={Button}
                    rightIcon={<GoTriangleDown />}
                  >
                    <Link
                      href="/"
                      h="1.5rem"
                      color={cl}
                      fontSize="1rem"
                      fontWeight="normal"
                      _hover={{ color: 'primaryDark' }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <ScrollLink
                        to={menu.id}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        {menu.name}{' '}
                      </ScrollLink>
                    </Link>
                  </MenuButton>
                  <MenuList border="1px solid #009BFF">
                    <MenuItem>Tutorial</MenuItem>
                    <ScrollLink
                      to={'faq'}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onClick={onCloseNav}
                    >
                      <MenuItem>FAQ</MenuItem>
                    </ScrollLink>
                    <MenuItem onClick={handleHelpModal}>Get Help</MenuItem>
                    {help && (
                      <HelpModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onClick={onClose}
                      />
                    )}
                  </MenuList>
                </Menu>
              )
            )}
          <Divider />
          <Box>
            <Flex w="12rem" justifyContent="space-between" flexDir="row">
              <Button
                bg={useColorModeValue('primary', 'primaryDark')}
                color="white"
                size="sm"
                fontWeight="normal"
                _hover={{
                  bg: 'brand.primary.600',
                  transition: 'color .2s',
                }}
                borderRadius="5px"
                onClick={() => goToPage(SIGN_IN)}
              >
                {SIGN_IN}
              </Button>
              <Button
                color="primary"
                borderColor="primary"
                variant="outline"
                size="sm"
                fontWeight="normal"
                _hover={{
                  color: 'white',
                  bg: 'primaryLight',
                  transition: 'color .2s',
                }}
                borderRadius="5px"
                onClick={() => goToPage(SIGN_UP)}
              >
                {SIGN_UP}
              </Button>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
  return (
    <chakra.header
      ref={ref}
      transition="all .3s ease-in-out"
      bg={canStick ? 'transparent' : bg}
      w={'full'}
      ml="auto"
      position={'fixed'}
      zIndex={20}
    >
      <chakra.div h="4.5rem" transition="all 1s ease-in-out" mx="auto" w="full">
        <Flex
          h="full"
          w="full"
          alignItems="center"
          justifyContent="space-between"
          px={['1rem', '2rem', '2rem', '6rem']}
        >
          <Flex align="flex-start" role="group">
            <Link
              href="/"
              _groupHover={{
                textDecoration: 'none',
              }}
            >
              <HStack>
                <Image
                  boxSize={['110px', '140px']}
                  objectFit="contain"
                  src="/assets/logo/logo-primary-250x150.png"
                  alt="test notion logo"
                />
              </HStack>
            </Link>
          </Flex>
          <Flex
            display={['none', 'none', 'flex', 'flex']}
            flexWrap="wrap"
            alignItems={'center'}
          >
            {menus &&
              menus.length > 0 &&
              menus.map(menu =>
                menu.type === 'regular' ? (
                  <Link
                    href="/"
                    h="1.5rem"
                    key={menu.name}
                    color={cl}
                    _notLast={{ mr: '0.8rem' }}
                    fontSize="1rem"
                    fontWeight="normal"
                    _hover={{ color: 'primaryDark' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <ScrollLink
                      to={menu.id}
                      href={menu.path}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                    >
                      {menu.name}{' '}
                    </ScrollLink>
                  </Link>
                ) : menu.type === 'notRegular' ? (
                  <Link
                    href="/"
                    h="1.5rem"
                    key={menu.name}
                    color={cl}
                    fontSize="1rem"
                    fontWeight="normal"
                    _hover={{ color: 'primaryDark' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <ScrollLink
                      to={menu.id}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onClick={() => router.push(menu.path)}
                    >
                      {menu.name}
                    </ScrollLink>
                  </Link>
                ) : (
                  <Menu key={menu.name}>
                    <MenuButton
                      color={cl}
                      _hover={{ color: 'primaryDark' }}
                      _focus={{ boxShadow: 'none' }}
                      background="none"
                      _active={{ background: 'none' }}
                      as={Button}
                      rightIcon={<GoTriangleDown />}
                    >
                      <Link
                        href="/"
                        h="1.5rem"
                        color={cl}
                        fontSize="1rem"
                        fontWeight="normal"
                        _hover={{ color: 'primaryDark' }}
                        _focus={{ boxShadow: 'none' }}
                      >
                        <ScrollLink
                          to={menu.id}
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                        >
                          {menu.name}{' '}
                        </ScrollLink>
                      </Link>
                    </MenuButton>
                    <MenuList border=" 1px solid #009BFF">
                      <MenuItem>Tutorial</MenuItem>
                      <ScrollLink
                        to={'faq'}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        <MenuItem>FAQ</MenuItem>
                      </ScrollLink>
                      <MenuItem onClick={handleHelpModal}>Get Help</MenuItem>
                      {help && (
                        <HelpModal
                          isOpen={isOpen}
                          onClose={onClose}
                          onClick={onClose}
                        />
                      )}
                    </MenuList>
                  </Menu>
                )
              )}
          </Flex>
          <Flex align="center" color="gray.400">
            {menus && menus.length > 0 && (
              <HStack spacing="5" display={['none', 'none', 'flex', 'flex']}>
                <Button
                  bg={useColorModeValue('primary', 'primaryDark')}
                  color="white"
                  size="sm"
                  fontWeight="normal"
                  _hover={{
                    bg: 'brand.primary.600',
                    transition: 'color .2s',
                  }}
                  borderRadius="5px"
                  onClick={() => goToPage(SIGN_IN)}
                >
                  {SIGN_IN}
                </Button>
                <Button
                  color="primary"
                  borderColor="primary"
                  variant="outline"
                  size="sm"
                  fontWeight="normal"
                  _hover={{
                    color: 'white',
                    bg: 'primaryLight',
                    transition: 'color .2s',
                  }}
                  borderRadius="5px"
                  onClick={() => goToPage(SIGN_UP)}
                >
                  {SIGN_UP}
                </Button>
              </HStack>
            )}
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{ base: '0', md: '3' }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
              _focus={{ boxShadow: 'none' }}
            />
            {menus && menus.length > 0 && (
              <IconButton
                display={['flex', 'flex', 'none', 'none']}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue('gray.800', 'inherit')}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={onOpenNav}
              />
            )}
          </Flex>
        </Flex>
        {MobileNavContent}
      </chakra.div>
    </chakra.header>
  )
}

export default NavBar
